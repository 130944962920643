// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NewQuotePolicy, QuotePolicy } from '../QuotePolicy/QuotePolicy';

export class CheckQuotePoliciesResults {
  readonly violatedPolicies: QuotePolicy[];

  constructor(data: { [key: string]: any } = {}) {
    this.violatedPolicies = (data.violatedPolicies ?? []).map((violatedPolicies: any) =>
      NewQuotePolicy(violatedPolicies),
    );
  }

  static zero(): CheckQuotePoliciesResults {
    const zeroValues: ExcludeMethodsDeep<CheckQuotePoliciesResults> = {
      violatedPolicies: [],
    };
    return new CheckQuotePoliciesResults(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************
  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewCheckQuotePoliciesResults = (
  props: PartialDeep<CheckQuotePoliciesResults, { recurseIntoArrays: true }>,
): CheckQuotePoliciesResults => new CheckQuotePoliciesResults(props);

export const NewCheckQuotePoliciesResultsFromDomainObject = (
  props: PartialDeep<DomainObject<CheckQuotePoliciesResults>, { recurseIntoArrays: true }>,
): CheckQuotePoliciesResults => new CheckQuotePoliciesResults(props);
