import { Typography } from '@mui/material';
import LogRocket from 'logrocket';
import { useContext } from 'react';

import { FilterLabel } from '../../components/DataTable/components/FilterTabs';
import {
  FormatButtonCell,
  FormatLinkCell,
  FormatNotSetCell,
} from '../../components/DataTable/components/SlabTableRowCells';
import {
  ApplyColumnChanges,
  DataTable,
  SharedDataTableProps,
} from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import { ApiTableDataParams, useApiTableData } from '../../components/DataTable/useApiTableData';
import Enums from '../../generated-types/Enums';
import { QuoteStatus } from '../../generated-types/QuoteStatus/QuoteStatus';
import {
  NewQuoteStatusAgg,
  QuoteStatusAgg,
} from '../../generated-types/QuoteStatusAgg/QuoteStatusAgg';
import { QuoteSummary } from '../../generated-types/QuoteSummary/QuoteSummary';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { SlabTheme } from '../../theme';
import { ListURLParams, QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { CurrencyDinero, DineroString } from '../../utils/Currency';
import { List } from '../../utils/List';
import { PossiblyAsyncResult } from '../../utils/Query';
import { NestedKeyOf } from '../../utils/Types';

const defaultColumns = (config: {
  requiresQuoteApprovals: boolean;
  onSendClick: (quote: QuoteSummary) => void;
  isExternalIDEnabled: boolean;
}): ColumnConfig<QuoteSummary, keyof QuoteSummary>[] => {
  const columns: ColumnConfig<QuoteSummary, keyof QuoteSummary>[] = [
    {
      id: 'quoteNumber',
      label: 'Number',
      type: 'string',
      isDisplayed: true,
      initialSortOrder: Enums.SortDirection.Descending,
      formatValueForWeb: (label, row) =>
        FormatLinkCell({
          label,
          link: `/quotes/${row.id}`,
        }),
    },
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'revisionNumber',
      label: 'Revision',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: 'projectName',
      label: 'Project',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'bidDate',
      label: 'Bid Date',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'plantName',
      label: 'Plant',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'companyName',
      label: 'Company',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'expirationDate',
      label: 'Expiration Date',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'statusName',
      label: 'Status',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'salesRepName',
      label: 'Sales Rep',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'isApprovedToSend',
      label: 'Send',
      type: 'boolean',
      isDisplayed: true,
      // The return type of this function is JSX.Element, but that type is not available in non-JSX files...
      formatValueForWeb: (_, row): JSX.Element => {
        if (config.requiresQuoteApprovals && !row.isApprovedToSend && !row.isSent) {
          return (
            <Typography color={SlabTheme.palette.SlabGray['400']} sx={{ whiteSpace: 'nowrap' }}>
              [needs approval]
            </Typography>
          );
        }
        return FormatButtonCell({
          label: 'Send',
          icon: 'send',
          onClick: () => config.onSendClick(row),
        });
      },
      formatValueForPdf: () => '',
    },
    {
      id: 'revenue',
      label: 'Est. Revenue',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'estimatedCuyd',
      label: 'Total CUYD',
      type: 'number',
      isDisplayed: false,
    },
    {
      id: 'creationDate',
      label: 'Creation Date',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'revisionDate',
      label: 'Revision Date',
      type: 'string',
      isDisplayed: false,
    },
  ];

  if (config.isExternalIDEnabled) {
    const externalIDColumn: ColumnConfig<QuoteSummary, keyof QuoteSummary> = {
      id: 'externalID',
      label: 'External ID',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (v) => FormatNotSetCell(v),
    };

    const nameIndex = columns.findIndex((column) => column.id === 'name');
    columns.splice(nameIndex + 1, 0, externalIDColumn);
  }

  return columns;
};

const buildTableDataModelConfig = ({
  requiresQuoteApprovals,
  onSendClick,
  statusResult,
  optionalColumnOverrides = [],
  optionalColumnRemovals = [],
  initialSortBy,
  initialFilterBy = [],
  includeTabs,
  isExternalIDEnabled,
}: {
  requiresQuoteApprovals: boolean;
  onSendClick: (quote: QuoteSummary) => void;
  statusResult: PossiblyAsyncResult<List<QuoteStatus> | undefined>;
  optionalColumnOverrides?: ColumnConfig<QuoteSummary, NestedKeyOf<QuoteSummary>>[];
  optionalColumnRemovals?: NestedKeyOf<QuoteSummary>[];
  initialSortBy: ListURLParams<QuoteSummary>['sortBy'];
  initialFilterBy: ListURLParams<QuoteSummary>['filterBy'];
  includeTabs: boolean;
  isExternalIDEnabled: boolean;
}): ApiTableDataParams<
  QuoteSummary,
  'GET quote summaries',
  QueryRouteBarrelTypes['GET quote summaries'],
  QuoteStatusAgg,
  'GET quote status aggregations',
  QueryRouteBarrelTypes['GET quote status aggregations']
> => {
  const columns = ApplyColumnChanges({
    columns: defaultColumns({ onSendClick, requiresQuoteApprovals, isExternalIDEnabled }),
    optionalColumnOverrides,
    optionalColumnRemovals,
  });

  const extractAggregations = (list: QuoteStatusAgg[]): { [id: string]: QuoteStatusAgg } => {
    const aggregationConfig = Object.fromEntries(
      (statusResult.data?.items ?? []).map((s) => [
        s.id,
        NewQuoteStatusAgg({ id: s.id, name: s.name }),
      ]),
    );
    list.forEach((agg) => {
      aggregationConfig[agg.id] = agg;
    });
    aggregationConfig[''] = list.reduce(
      (totalAgg: QuoteStatusAgg, statusAgg: QuoteStatusAgg): QuoteStatusAgg =>
        NewQuoteStatusAgg({
          id: totalAgg.id,
          name: totalAgg.name,
          count: totalAgg.count + statusAgg.count,
          totalRevenue: totalAgg.totalRevenue.add(statusAgg.totalRevenue) ?? totalAgg.totalRevenue,
        }),
      QuoteStatusAgg.zero(),
    );
    return aggregationConfig;
  };

  const makeAggregationArgs = (
    rowsArgs: QueryRouteBarrelTypes['GET quote summaries']['args'],
  ): QueryRouteBarrelTypes['GET quote status aggregations']['args'] => {
    const filterBy = (rowsArgs.queryParams?.filterBy ?? []).filter(
      (param) => param.operation !== 'equals' || !(param.name as string).startsWith('status'),
    );
    if (filterBy.length === 0) {
      return {};
    }
    return { queryParams: { filterBy } };
  };

  return {
    rowsRouteKey: 'GET quote summaries',
    extractRows: (quoteData) => quoteData.items,
    initialSortBy,
    initialFilterBy,
    aggregationsRouteKey: 'GET quote status aggregations',
    aggregationsQueryOptions: { enabled: includeTabs },
    makeAggregationArgs,
    extractAggregations,
    makeColumnConfigs: () => columns,
  };
};

type QuoteTableProps = Omit<
  SharedDataTableProps<QuoteSummary, QuoteStatusAgg>,
  'initialTabFilter'
> & {
  initialSortBy: ListURLParams<QuoteSummary>['sortBy'];
  initialFilterBy?: ListURLParams<QuoteSummary>['filterBy'];
  initialFilterStatusName?: string;
  includeTabs?: boolean;
  /**
   * `onSendClick` is the function that will be invoked when the `sendMessage`
   * button is pressed. If that button is omitted via the `optionalColumnRemovals` then
   * this may accept an empty function like `onSendClick={(): void => {}}`
   */
  onSendClick: (quote: QuoteSummary) => void;
};

export const QuoteTable = ({
  initialSortBy,
  initialFilterBy,
  initialFilterStatusName,
  includeTabs = false,
  onSendClick,
  optionalColumnOverrides,
  optionalColumnRemovals,
  ...dataTableProps
}: QuoteTableProps): JSX.Element => {
  const { userInfo } = useContext(SlabContext);

  const isExternalIDEnabled = userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagIntegrationExport,
  ]);

  const statusResult = useSlabQuery('GET quote statuses', {}, { enabled: includeTabs });
  const { data: statusList } = statusResult;

  const initialFilters = (initialFilterBy ?? []).concat(
    (dataTableProps.filterCheckboxes ?? [])
      .filter(({ defaultChecked }) => defaultChecked)
      .map(({ filter }) => filter),
  );
  const tableModel = useApiTableData(
    buildTableDataModelConfig({
      requiresQuoteApprovals: userInfo.tenant.requiresQuoteApprovals,
      initialSortBy,
      initialFilterBy: initialFilters,
      statusResult,
      onSendClick,
      optionalColumnOverrides,
      optionalColumnRemovals,
      includeTabs,
      isExternalIDEnabled,
    }),
  );

  const filterKeys = ((): FilterLabel<QuoteSummary, QuoteStatusAgg>[] | undefined => {
    if (!includeTabs) {
      return undefined;
    }
    return [
      {
        label: 'All',
        filter: null,
        additionalLabels: (aggregations: {
          [id: string]: QuoteStatusAgg | undefined;
        }): string[] => {
          const agg = aggregations[''] ?? QuoteStatusAgg.zero();

          return [DineroString({ dinero: CurrencyDinero(agg.totalRevenue) })];
        },
        resultCount: (aggregations: { [id: string]: QuoteStatusAgg | undefined }): number => {
          const agg = aggregations[''] ?? QuoteStatusAgg.zero();

          return agg.count;
        },
      },
      ...(statusList?.items ?? []).map(
        (cqs): FilterLabel<QuoteSummary, QuoteStatusAgg> => ({
          label: cqs.name,
          filter: {
            name: 'statusName',
            operation: Enums.FilterOperation.Equals,
            value: cqs.name,
          },
          additionalLabels: (aggregations: {
            [id: string]: QuoteStatusAgg | undefined;
          }): string[] => {
            let agg = aggregations[cqs.id];

            // We are unable to recreate this locally, so log it to catch it next time.
            if (agg === undefined) {
              LogRocket.error(
                'additionalLabels: Aggregation for specific quote status is undefined',
                {
                  aggregations,
                  quoteStatusId: cqs.id,
                },
              );
              agg = QuoteStatusAgg.zero();
            }
            return [DineroString({ dinero: CurrencyDinero(agg.totalRevenue) })];
          },
          resultCount: (aggregations: { [id: string]: QuoteStatusAgg }): number => {
            let agg = aggregations[cqs.id];

            // We are unable to recreate this locally, so log it to catch it next time.
            if (agg === undefined) {
              LogRocket.error('resultCount: Aggregation for specific quote status is undefined', {
                aggregations,
                quoteStatusId: cqs.id,
              });
              agg = QuoteStatusAgg.zero();
            }
            return agg.count;
          },
        }),
      ),
    ];
  })();

  const initialTabFilter =
    filterKeys === undefined
      ? undefined
      : filterKeys.find((fk) => fk.filter?.value === initialFilterStatusName);

  return (
    <DataTable
      tableModel={tableModel}
      tabFilters={filterKeys}
      initialTabFilter={initialTabFilter}
      initialSortBy={initialSortBy}
      initialFilterBy={initialFilterBy}
      {...dataTableProps}
    />
  );
};
