import { Box, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useContext } from 'react';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Product } from '../../../generated-types/Product/Product';
import { QuoteStatus } from '../../../generated-types/QuoteStatus/QuoteStatus';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { SlabContext } from '../../../SlabContext';
import { FormErrorNotification } from '../../../utils/FormikHelpers';
import { isValidUUID, NIL_UUID } from '../../../utils/UUID';
import { QuoteFormikType, QuoteSchemaFormik, QuoteSubmitType } from '../QuoteFormik';
import { QuoteDrawerSections, QuoteSection } from './QuoteDrawerSections';
import { CanEditCurrentRevision } from './QuoteDrawerUtilities';

type QuoteDrawerMainPageProps = {
  formikQuote: QuoteFormikType;
  curProductsRef: React.MutableRefObject<Record<number, Product | null>>;

  submitType: QuoteSubmitType;
  submitForm: (values: QuoteFormikType) => Promise<void> | void;
  cancelForm: () => Promise<void> | void;
  isSubmitPending: boolean;

  /** @default 'Quote Information' */
  initialSection: QuoteSection | undefined;
};

export const QuoteDrawerMainPage = ({
  formikQuote,
  initialSection = 'Quote Information*',
  curProductsRef,
  submitType,
  submitForm,
  cancelForm,
  isSubmitPending,
}: QuoteDrawerMainPageProps): JSX.Element | null => {
  const ctx = useContext(SlabContext);

  const isNew = formikQuote.id === NIL_UUID;

  const { isLoading, isError, data: quoteStatusList } = useSlabQuery('GET quote statuses', {});

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (isError || quoteStatusList === undefined) {
    return <div>ERROR</div>;
  }

  const canEditCurrentRevision = CanEditCurrentRevision({
    userInfo: ctx.userInfo,
    savedQuoteStatus: isValidUUID(formikQuote.status.id)
      ? quoteStatusList.items.find((s) => s.id === formikQuote.status.id)
      : QuoteStatus.zero(),
  });

  const drawerTitle = ((): string => {
    if (submitType === 'saveAsNewRevision') {
      return 'Create a quote revision';
    }
    if (isNew || submitType === 'saveAsNewQuote') {
      return 'Create a quote';
    }
    return 'Edit a quote';
  })();

  return (
    <Formik
      validationSchema={QuoteSchemaFormik}
      initialValues={formikQuote}
      // Show pre-existing "UOM issue" product errors before interacting with that part of the form.
      validateOnMount={!isNew}
      initialTouched={{
        mainProducts: formikQuote.mainProducts.map(() => ({
          product: {
            option: true,
            incompatibleMixBatchUnits: true,
            allowIncompatibleMixBatchUnits: true,
          },
        })),
        additionalProducts: formikQuote.additionalProducts.map(() => ({
          product: {
            option: true,
            incompatibleMixBatchUnits: true,
            allowIncompatibleMixBatchUnits: true,
          },
        })),
      }}
      onSubmit={submitForm}
    >
      {(formikBag): JSX.Element => (
        <Box padding='5rem 3.5rem 5rem 3.5rem'>
          <FormErrorNotification />
          <Typography variant='h1' fontSize='2rem'>
            {drawerTitle}
          </Typography>
          <QuoteDrawerSections initialSection={initialSection} curProductsRef={curProductsRef} />
          <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
            <Grid item xs={4}>
              <ButtonPill
                text='cancel'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikQuote });
                  cancelForm();
                }}
                disabled={isSubmitPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='reset'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikQuote });

                  curProductsRef.current = {};
                }}
                disabled={!formikBag.dirty || isSubmitPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='save'
                variant='primary'
                onClick={async (): Promise<void> => {
                  formikBag.submitForm();
                }}
                disabled={
                  (submitType === 'save' && !canEditCurrentRevision) ||
                  !(
                    formikBag.dirty ||
                    formikBag.touched.mainProducts !== undefined ||
                    formikBag.touched.additionalProducts !== undefined ||
                    isSubmitPending
                  )
                }
              />
            </Grid>
          </Grid>
          <Typography marginTop='12px'>
            To save, address any highlighted issues in red first.
          </Typography>
        </Box>
      )}
    </Formik>
  );
};
