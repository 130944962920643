import { Box, Grid, Typography } from '@mui/material';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';

type QuoteDrawerApprovedPageProps = {
  closeDrawer: () => Promise<void> | void;
  sendQuote: () => Promise<void> | void;
};

export const QuoteDrawerApprovedPage = ({
  closeDrawer,
  sendQuote,
}: QuoteDrawerApprovedPageProps): JSX.Element => (
  <Box padding='5rem 3.5rem'>
    <Typography variant='h1' fontSize='2rem'>
      Quote Saved
    </Typography>

    <p>
      Your quote complies with all approval policies. If you’re done editing the quote, you can
      click Send Quote to email it to the customer.
    </p>

    <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
      <Grid item xs={6}>
        <ButtonPill text='Close' variant='secondary' onClick={closeDrawer} />
      </Grid>
      <Grid item xs={6}>
        <ButtonPill text='Send Quote' variant='primary' onClick={sendQuote} />
      </Grid>
    </Grid>
  </Box>
);
