// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class QuoteStatus {
  readonly id: string;
  readonly name: string;
  readonly priority: number;
  readonly isArchived: boolean;
  readonly isExportable: boolean;
  readonly isDraft: boolean;
  readonly isApprovalRequest: boolean;
  readonly isRejected: boolean;
  readonly isApprovedToSend: boolean;
  readonly isSent: boolean;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.priority = data.priority === undefined ? 0 : data.priority;
    this.isArchived = data.isArchived === undefined ? false : data.isArchived;
    this.isExportable = data.isExportable === undefined ? false : data.isExportable;
    this.isDraft = data.isDraft === undefined ? false : data.isDraft;
    this.isApprovalRequest = data.isApprovalRequest === undefined ? false : data.isApprovalRequest;
    this.isRejected = data.isRejected === undefined ? false : data.isRejected;
    this.isApprovedToSend = data.isApprovedToSend === undefined ? false : data.isApprovedToSend;
    this.isSent = data.isSent === undefined ? false : data.isSent;
  }

  static zero(): QuoteStatus {
    const zeroValues: ExcludeMethodsDeep<QuoteStatus> = {
      id: NIL_UUID,
      name: '',
      priority: 0,
      isArchived: false,
      isExportable: false,
      isDraft: false,
      isApprovalRequest: false,
      isRejected: false,
      isApprovedToSend: false,
      isSent: false,
    };
    return new QuoteStatus(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /**
   * Returns true if a user may set a quote's status to destination when the
   * quote's current status is this.
   *
   * When changing this method, consider whether you should also update the Go
   * implementation (named `AllowsUserStatusChange` at
   * internal/domain/quote.go).  The backend does have slightly different
   * requirements but in general these implementations should be similar.
   */
  canTransitionTo(destination: QuoteStatus): boolean {
    const thisIsZeroValue =
      !this.isDraft &&
      !this.isApprovalRequest &&
      !this.isRejected &&
      !this.isApprovedToSend &&
      !this.isSent &&
      !this.isArchived;
    // Zero value status generally means that we're creating a new quote, not
    // editing an existing one.  Quotes must be created in a draft status.
    if (thisIsZeroValue) {
      return destination.isDraft;
    }
    // Transitions between actual statuses are mostly restricted to same-
    // category statuses, because changing the approval workflow stage must
    // generally happen via structured actions where additional constraints
    // can be enforced by our backend.
    //
    // Exception: quotes may be freely moved "backward" from isApprovalRequest
    // to isDraft.  The backend also freely allows quotes to move from isDraft
    // to isApprovalRequest; on the frontend that is a "structured" action that
    // simply consists of a dedicated "send for review" button.
    if (this.isDraft && destination.isDraft) {
      return true;
    }
    if (this.isApprovalRequest && (destination.isDraft || destination.isApprovalRequest)) {
      return true;
    }
    if (this.isRejected && destination.isRejected) {
      return true;
    }
    if (this.isApprovedToSend && destination.isApprovedToSend) {
      return true;
    }
    if (this.isSent && destination.isSent) {
      return true;
    }
    // Archived statuses are dead-ends and accessible from any other status.
    return destination.isArchived;
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuoteStatus = (
  props: PartialDeep<QuoteStatus, { recurseIntoArrays: true }>,
): QuoteStatus => new QuoteStatus(props);

export const NewQuoteStatusFromDomainObject = (
  props: PartialDeep<DomainObject<QuoteStatus>, { recurseIntoArrays: true }>,
): QuoteStatus => new QuoteStatus(props);
