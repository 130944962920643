import { Box, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { Input } from '../../../components/Input/Input';
import { YearSelector } from '../../../components/YearSelector/YearSelector';
import { ParseServerDate } from '../../../utils/DateHelpers';
import { ProjectFormikType } from '../ProjectFormik';

type ForecastsSectionProps = {
  initialForecastYear: number;
};

export const ForecastsSection = ({ initialForecastYear }: ForecastsSectionProps): JSX.Element => {
  const formikBag = useFormikContext<ProjectFormikType>();
  const [selectedYear, setSelectedYear] = useState(initialForecastYear);

  // If the initialForecastYear prop changes, set it to the selected year.
  useEffect(() => {
    setSelectedYear(initialForecastYear);
  }, [initialForecastYear]);

  const forecastsExtra = useMemo(
    () =>
      formikBag.values.forecasts.map((f, i) => {
        const { year, month } = ParseServerDate(f.intervalStart);
        return { ...f, formikIdx: i, year, month };
      }),
    [formikBag.values.forecasts],
  );

  const possibleYears = useMemo(
    () =>
      _.uniqBy(forecastsExtra, (f) => f.year).map((yr) => ({
        year: yr.year,
        hasValues: forecastsExtra.some((f) => f.value !== '' && f.year === yr.year),
      })),
    [formikBag.values.forecasts],
  );

  const filteredForecastsByYear = useMemo(
    () => forecastsExtra.filter((f) => f.year === selectedYear),
    [formikBag.values.forecasts, selectedYear],
  );

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <YearSelector
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        yearOpts={possibleYears}
        selectWidth='fullWidth'
      />

      <Box pt='1rem'>
        <Grid key='forecast-container' container spacing={2}>
          {filteredForecastsByYear.map((ff) => (
            <Grid item xs={6} key={`${ff.year}-${ff.month}`}>
              <Input
                name={`forecasts.${ff.formikIdx}.value`}
                label={ParseServerDate(ff.intervalStart).monthLong ?? ''}
                type='number'
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
