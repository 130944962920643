import { Box, Typography } from '@mui/material';
import { useContext } from 'react';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import {
  FormatLinkCell,
  FormatNotSetCell,
} from '../../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  InitialTableData,
  useLocalTableData,
} from '../../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Currency } from '../../../generated-types/Currency/Currency';
import Enums from '../../../generated-types/Enums';
import { Units } from '../../../generated-types/generated-units';
import { NewQuote } from '../../../generated-types/Quote/Quote';
import { QuoteProduct } from '../../../generated-types/QuoteProduct/QuoteProduct';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { SlabContext } from '../../../SlabContext';
import { convertExternalID } from '../../../utils/ExternalIDHelpers';
import { PossiblyAsyncResult } from '../../../utils/Query';
import { NestedKeyOf } from '../../../utils/Types';

type QuoteAdditionalProductListRow = {
  order: number;
  id: string;
  alternateID: string;
  name: string;
  category: string;
  price: Currency;
  measurementUnit: string;
  suggestedPrice: Currency | null;
  externalID: string | null;
};

const buildTableDataModelConfig = ({
  productsResult,
}: {
  productsResult: PossiblyAsyncResult<QuoteProduct[] | undefined>;
}): InitialTableData<QuoteAdditionalProductListRow, { id: string }, QuoteProduct[] | undefined> => {
  const ctx = useContext(SlabContext);
  const isExternalIDEnabled = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagIntegrationExport,
  ]);

  const transformRows = (quoteProducts: QuoteProduct[] = []): QuoteAdditionalProductListRow[] => {
    const quote = NewQuote({ products: quoteProducts });

    const products = quote.filterProducts(Enums.QuoteProductKind.Additional);

    return products.map(
      (p, idx): QuoteAdditionalProductListRow => ({
        // 'order' uses index assuming that the products are already sorted by order.
        order: idx + 1,

        id: p.product.id,
        alternateID: p.product.alternateID,
        name: p.product.name,
        category: p.product.category,
        price: p.price,
        measurementUnit: Units[p.product.measurementUnit].label,
        suggestedPrice: p.calculateSuggestedPrice(),
        externalID: convertExternalID(p.product.externalID),
      }),
    );
  };

  const columns: ColumnConfig<
    QuoteAdditionalProductListRow,
    NestedKeyOf<QuoteAdditionalProductListRow>
  >[] = [
    {
      id: 'order',
      label: 'Order',
      type: 'number',
      isDisplayed: false,
    },
    {
      id: 'alternateID',
      label: 'Product ID',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/products/${row.id}` }),
    },
    {
      id: 'category',
      label: 'Category',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'price',
      label: 'Price',
      type: 'Currency',
      isDisplayed: true,
    },
    {
      id: 'measurementUnit',
      label: 'Unit',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'suggestedPrice',
      label: 'Suggested price',
      type: 'Currency',
      isDisplayed: false,
    },
  ];

  if (isExternalIDEnabled) {
    const externalIDColumn: ColumnConfig<
      QuoteAdditionalProductListRow,
      NestedKeyOf<QuoteAdditionalProductListRow>
    > = {
      id: 'externalID',
      label: 'External ID',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (v) => FormatNotSetCell(v),
    };

    const alternateIDIndex = columns.findIndex((column) => column.id === 'alternateID');

    columns.splice(alternateIDIndex + 1, 0, externalIDColumn);
  }

  return {
    rowData: productsResult,
    transformRows,
    columns,
    initialSortPath: 'order',
  };
};

type AdditionalProductTableProps = {
  quoteId: string;
  onEditServices: () => void;
};

export const AdditionalProductTable = ({
  quoteId,
  onEditServices,
}: AdditionalProductTableProps): JSX.Element => {
  const productsResult = useSlabQuery('GET quote products by quote ID', {
    pathParams: {
      id: quoteId,
    },
  });
  const { isLoading, isError, data: quoteProducts } = productsResult;

  const tableModel = useLocalTableData(buildTableDataModelConfig({ productsResult }));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || quoteProducts === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' paddingBottom='1.5rem' alignItems='center'>
        <Typography variant='h4'>Additional services</Typography>
        <ButtonPill
          text='edit services'
          variant='primary'
          size='small'
          onClick={onEditServices}
          icon='edit'
        />
      </Box>
      <DataTable tableName='quote-additional-services' tableModel={tableModel} />
    </Box>
  );
};
