import { Box, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { FilterCheckbox } from '../../components/DataTable/DataTable';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { MarkAsSentAndShowToast, NylasFlyout } from '../../components/Nylas/NylasFlyout';
import { Page } from '../../components/Page/Page';
import Enums from '../../generated-types/Enums';
import { QuoteSummary } from '../../generated-types/QuoteSummary/QuoteSummary';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { NIL_UUID } from '../../utils/UUID';
import { makeDefaultFormikQuote } from './components/QuoteDrawerUtilities';
import { QuoteDrawer } from './QuoteDrawer';
import { QuoteStatusDrawer } from './QuoteStatusDrawer';
import { QuoteTable } from './QuoteTable';

export const QuoteList = (): JSX.Element => {
  const ctx = useContext(SlabContext);
  const { userInfo } = useContext(SlabContext);

  const [quoteId, setQuoteId] = useState<string | null>(null);
  const [isEmailOpen, setIsEmailOpen] = useState(false);

  const { toastHook: quoteToastHook, ...quoteDrawerProps } = useDrawerManager({
    baseUrl: '/quotes',
    resourceTypeName: 'quote',
    drawerProps: {
      resourceId: quoteId,
    },
  });
  const { toastHook: quoteStatusToastHook, ...quoteStatusDrawerProps } = useDrawerManager({
    baseUrl: '/quotes', // unused
    resourceTypeName: 'quote status',
    drawerProps: {
      resourceId: quoteId,
    },
  });

  const {
    isLoading: isLoadingStatuses,
    isError: isErrorStatuses,
    data: statusList,
  } = useSlabQuery('GET quote statuses', {});

  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    data: userList,
  } = useSlabQuery('GET users', {});

  const markAsSent = useSlabMutation('POST mark quote as sent');

  const isLoading = isLoadingStatuses || isLoadingUsers;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorStatuses || isErrorUsers;
  const isDataUndefined = statusList === undefined || userList === undefined;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const filterCheckboxes: FilterCheckbox<QuoteSummary>[] = [
    {
      label: 'Hide archived quotes',
      filter: {
        name: 'isArchived',
        operation: Enums.FilterOperation.Equals,
        value: false,
      },
      defaultChecked: true,
    },
    {
      label: 'Hide previous revisions',
      filter: {
        operation: Enums.FilterOperation.Equals,
        name: 'isLatestRevision',
        value: true,
      },
      defaultChecked: true,
    },
  ];

  const initialFilterStatusName = userInfo.hasRoles([Enums.RoleName.Manager])
    ? statusList.items.find((s) => s.isApprovalRequest)?.name
    : undefined;

  return (
    <Page title='Quotes'>
      {quoteToastHook.toast}
      {quoteStatusToastHook.toast}
      <QuoteDrawer
        {...quoteDrawerProps}
        makeDefaultFormikQuote={makeDefaultFormikQuote}
        onRequestSuccess={(): void => {
          quoteToastHook.showToast('success');
        }}
        onNylasSuccess={() => {
          quoteToastHook.showToast('success', 'The quote was sent successfully');
        }}
        onRequestError={() => {
          quoteToastHook.showToast('error');
        }}
        onNylasError={() => {
          quoteToastHook.showToast('error', 'Failed to send the quote');
        }}
        submitType={quoteId === null ? 'saveAsNewRevision' : 'save'}
      />
      <QuoteStatusDrawer {...quoteStatusDrawerProps} />
      <NylasFlyout
        isOpen={isEmailOpen}
        setIsOpen={setIsEmailOpen}
        onClose={(): void => {
          setIsEmailOpen(false);
        }}
        onSuccess={async (): Promise<void> => {
          await MarkAsSentAndShowToast({
            quoteId: quoteId ?? '',
            markAsSentMutation: markAsSent,
            toastHook: quoteToastHook,
          });
        }}
        onError={(err): void =>
          quoteToastHook.showToast('error', ServerErrorMessage(err) ?? 'Unable to send quote')
        }
        quoteId={quoteId ?? NIL_UUID}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Quotes</Typography>
        <ButtonPill
          text='create quote'
          variant='primary'
          onClick={(): void => {
            setQuoteId(null);
            quoteDrawerProps.setIsOpen(true);
          }}
          icon='add'
        />
      </Box>

      <Box height='400px'>
        <QuoteTable
          initialSortBy={{
            name: 'name',
            direction: Enums.SortDirection.Ascending,
          }}
          initialFilterStatusName={initialFilterStatusName}
          onSendClick={(quote): void => {
            setQuoteId(quote.id);
            setIsEmailOpen(true);
          }}
          onEditPress={(row): void => {
            setQuoteId(row.id);
            if (
              !ctx.userInfo.tenant.requiresQuoteApprovals ||
              row.isDraft ||
              row.isApprovalRequest
            ) {
              quoteDrawerProps.setIsOpen(true);
            } else {
              quoteStatusDrawerProps.setIsOpen(true);
            }
          }}
          includeTabs
          includePrint={{ title: 'Quotes' }}
          marketSelectorOpts={{ enabled: true }}
          plantSelectorOpts={{ enabled: true }}
          filterCheckboxes={filterCheckboxes}
          tableName='quotes'
        />
      </Box>
    </Page>
  );
};
