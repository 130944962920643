import { merge as mergeObjects } from 'lodash';
import LogRocket from 'logrocket';
import { DateTime } from 'luxon';
import { PartialDeep } from 'type-fest';
import * as Yup from 'yup';

import {
  AdditionalProjectOrQuoteProductsFormikSchema,
  MainProjectOrQuoteProductsFormikSchema,
  ProjectOrQuoteProductEditableFieldsFormikType,
  ProjectOrQuoteProductFormikType,
  QuoteProductToProjectOrQuoteProductFormik,
} from '../../components/ProductSection/ProjectOrQuoteProductFormik';
import { NewCompany } from '../../generated-types/Company/Company';
import Enums from '../../generated-types/Enums';
import { NewQuote, Quote } from '../../generated-types/Quote/Quote';
import {
  NewQuoteProductFromDomainObject,
  QuoteProduct,
} from '../../generated-types/QuoteProduct/QuoteProduct';
import { QuoteStatus } from '../../generated-types/QuoteStatus/QuoteStatus';
import { DomainObject } from '../../utils/ApiClient';
import { QuotePriceEscalationToFormik } from '../../utils/FormikHelpers';
import { RemoveNullProperties } from '../../utils/Types';
import { isValidUUID, NIL_UUID } from '../../utils/UUID';
import {
  TestForUndefined,
  YupNullableLocalDate,
  YupNullableReference,
  YupNullableString,
  YupNumber,
  YupQuotePriceEscalations,
  YupReference,
  YupSchemaNullableReferenceType,
  YupSchemaNumberType,
  YupSchemaReferenceType,
} from '../../utils/YupHelpers';
import { NewDeliveryCostOrNullFromFormik } from '../Plants/PlantFormik';

export type QuoteStatusFormikType = YupSchemaReferenceType;
type QuoteStatusSchemaFormikType = Yup.SchemaOf<YupSchemaReferenceType>;

export const QuoteStatusSchemaFormik: QuoteStatusSchemaFormikType = YupReference('quote status');

export const FormikQuoteStatus = (
  quoteStatusData: QuoteStatus | undefined,
): YupSchemaReferenceType => {
  if (quoteStatusData === undefined || quoteStatusData.id === NIL_UUID) {
    return { id: null, option: null };
  }
  return {
    id: quoteStatusData.id,
    option: {
      value: quoteStatusData.id,
      label: quoteStatusData.name,
    },
  };
};

export type QuoteFormikType = DomainObject<
  Omit<
    Quote,
    | 'project'
    | 'company'
    | 'contact'
    | 'products'
    | 'user'
    | 'quoteConfig'
    | 'revenue'
    | 'estimatedVolume'
    | 'revisionNumber'
    | 'status'
    | 'policyViolations'
  >
> & {
  project: YupSchemaReferenceType;
  revisionNumber: YupSchemaNumberType;
  company: YupSchemaNullableReferenceType;
  contact: YupSchemaNullableReferenceType;
  mainProducts: Omit<ProjectOrQuoteProductFormikType, 'displayOnly'>[];
  additionalProducts: Omit<ProjectOrQuoteProductFormikType, 'displayOnly'>[];
  user: YupSchemaReferenceType;
  quoteConfig: YupSchemaNullableReferenceType;
  status: YupSchemaReferenceType;
};

const sharedQuoteSchemaFields = {
  status: YupReference('Status'),
  quoteNumber: Yup.string().default('').label('Quote number'),
  name: YupNullableString('Name'),
  revisionNumber: YupNumber({
    label: 'Revision number',
    integer: true,
    positive: true,
  }),
  notes: YupNullableString('Notes'),
  expirationDate: YupNullableLocalDate('Expiration date'),
  project: YupReference('Project'),
  company: YupNullableReference('Company'),
  contact: YupNullableReference('Contact'),
  user: YupReference('User'),
  creationDate: YupNullableLocalDate('Creation date'),
  revisionDate: YupNullableLocalDate('Revision date'),
  quoteConfig: YupNullableReference('Quote config'),
  priceEscalations: YupQuotePriceEscalations('Price escalations'),
};

type QuoteSchemaFormikType = Yup.SchemaOf<
  Omit<
    QuoteFormikType,
    'id' | 'externalID' | 'submitType' | 'mainProducts' | 'additionalProducts'
  > & {
    mainProducts: ProjectOrQuoteProductEditableFieldsFormikType[];
    additionalProducts: ProjectOrQuoteProductEditableFieldsFormikType[];
  }
>;

/** This schema will be used for yup validation */
export const QuoteSchemaFormik: QuoteSchemaFormikType = Yup.object()
  .shape({
    ...sharedQuoteSchemaFields,

    mainProducts: MainProjectOrQuoteProductsFormikSchema,
    additionalProducts: AdditionalProjectOrQuoteProductsFormikSchema,
  })
  .test(TestForUndefined('QuoteSchemaFormik'));

type QuoteSchemaWireType = Yup.SchemaOf<
  Omit<
    QuoteFormikType,
    'id' | 'externalID' | 'submitType' | 'template' | 'mainProducts' | 'additionalProducts'
  > & {
    products: ProjectOrQuoteProductEditableFieldsFormikType[];
  }
>;

export const QuoteStatusSchemaWire: Yup.SchemaOf<QuoteStatus> = YupReference('');

/** This schema will be used to cast with the contactenation of mainProducts + additionalProducts */
export const QuoteSchemaWire: QuoteSchemaWireType = Yup.object().shape({
  ...sharedQuoteSchemaFields,

  products: AdditionalProjectOrQuoteProductsFormikSchema,
});

export type QuoteSubmitType = 'save' | 'saveAsNewRevision' | 'saveAsNewQuote';

type FormikQuoteOpts = Partial<Quote> | undefined;

/** Returns the form data for a Quote. */
export const FormikQuote = (
  quoteData: FormikQuoteOpts,
  usesDispatchCustomer: boolean,
  submitType: QuoteSubmitType,
): QuoteFormikType => {
  const quoteWithProducts =
    quoteData !== undefined ? NewQuote({ products: quoteData.products ?? [] }) : Quote.zero();

  const mainFormikQuoteProducts = quoteWithProducts
    .filterProducts(Enums.QuoteProductKind.Primary)
    .map(QuoteProductToProjectOrQuoteProductFormik);

  const additionalFormikQuoteProducts = quoteWithProducts
    .filterProducts(Enums.QuoteProductKind.Additional)
    .map(QuoteProductToProjectOrQuoteProductFormik);

  const quotePriceEscalationsFormik =
    quoteData?.priceEscalations !== undefined
      ? quoteData.priceEscalations.map(QuotePriceEscalationToFormik)
      : [];

  const name =
    submitType === 'saveAsNewQuote' && isValidUUID(quoteData?.id)
      ? `${quoteData?.name} - Copy`
      : quoteData?.name ?? null;

  const revisionNumber = ((): YupSchemaNumberType => {
    if (submitType === 'saveAsNewQuote') {
      return 1;
    }
    if (submitType === 'saveAsNewRevision') {
      return (quoteData?.revisionNumber ?? 1) + 1;
    }
    return quoteData?.revisionNumber ?? 1;
  })();

  return {
    id: submitType === 'save' ? quoteData?.id ?? NIL_UUID : NIL_UUID,
    externalID: submitType === 'save' ? quoteData?.externalID ?? null : null,
    quoteNumber: submitType === 'saveAsNewQuote' ? '' : quoteData?.quoteNumber ?? '',
    name,
    revisionNumber,
    status: FormikQuoteStatus(quoteData?.status),
    notes: quoteData?.notes ?? null,
    expirationDate: quoteData?.expirationDate?.toISODate() ?? null,
    creationDate: quoteData?.creationDate?.toISODate() ?? DateTime.now().toISODate(),
    revisionDate: quoteData?.revisionDate?.toISODate() ?? null,
    user:
      quoteData?.user?.id === undefined || quoteData.user.id === NIL_UUID
        ? { id: null, option: null }
        : {
            id: quoteData.user.id,
            option: {
              value: quoteData.user.id,
              label: quoteData.user.fullName(),
            },
          },
    project:
      quoteData?.project?.id === undefined || quoteData.project.id === NIL_UUID
        ? { id: null, option: null }
        : {
            id: quoteData.project.id,
            option: {
              value: quoteData.project.id,
              label: quoteData.project.name,
            },
          },
    company:
      quoteData?.company?.id === undefined || quoteData.company.id === NIL_UUID
        ? { id: null, option: null }
        : {
            id: quoteData.company.id,
            option: {
              value: quoteData.company.id,
              label: quoteData.company.name,
              sublabels: NewCompany(quoteData.company).lookupSublabels(usesDispatchCustomer),
            },
          },
    contact:
      quoteData?.contact?.id === undefined || quoteData.contact.id === NIL_UUID
        ? { id: null, option: null }
        : {
            id: quoteData.contact.id,
            option: {
              value: quoteData.contact.id,
              label: quoteData.contact.fullName(),
            },
          },
    mainProducts: mainFormikQuoteProducts,
    additionalProducts: additionalFormikQuoteProducts,
    quoteConfig:
      quoteData?.quoteConfig?.id === undefined || quoteData.quoteConfig.id === NIL_UUID
        ? { id: null, option: null }
        : {
            id: quoteData.quoteConfig.id,
            option: {
              value: quoteData.quoteConfig.id,
              label: quoteData.quoteConfig.name,
            },
          },
    priceEscalations: quotePriceEscalationsFormik,
  };
};

// We can safely assume `false` for `usesDispatchCustomer` as that only affects initial values.
export const EmptyFormikQuote = FormikQuote(undefined, false, 'save');

export const NewQuoteFromFormik = (values: QuoteFormikType): Quote => {
  const products = [...values.mainProducts, ...values.additionalProducts].map((p) => ({
    ...p,
    deliveryCosts: NewDeliveryCostOrNullFromFormik(p.deliveryCosts),
  }));
  const priceEscalations = values.priceEscalations.map((qpe) => {
    const strippedChangeRatio = (qpe.changeRatio ?? '').replaceAll(',', '');
    const qpeChangeRatio = Number.isNaN(parseFloat(strippedChangeRatio))
      ? null
      : (parseFloat(strippedChangeRatio) / 100).toFixed(3);
    return {
      ...qpe,
      changeRatio: qpeChangeRatio,
    };
  });
  // TODO #2219: Need a NewQuoteFromForm wrapper for this case
  const wireQuote = new Quote(
    mergeObjects(Quote.zero(), {
      ...values,
      products,
      priceEscalations,
    }),
  );
  return wireQuote;
};

/* Converts form data to a QuoteProduct domain object. */
export const NewQuoteProductFromFormik = (
  values: Omit<ProjectOrQuoteProductFormikType, 'displayOnly'>,
  dirtyEdits?: PartialDeep<Omit<ProjectOrQuoteProductFormikType, 'displayOnly'>>,
): QuoteProduct => {
  const input: Omit<ProjectOrQuoteProductFormikType, 'displayOnly'> = mergeObjects(
    {},
    values,
    dirtyEdits,
  );

  let castData: Parameters<typeof NewQuoteProductFromDomainObject>[0];
  try {
    castData = RemoveNullProperties(
      QuoteSchemaFormik.cast({ additionalProducts: [input] }).additionalProducts?.[0] ?? {},
    );
  } catch (e) {
    try {
      QuoteSchemaFormik.validateSyncAt(
        'additionalProducts',
        { additionalProducts: [input] },
        { strict: true, abortEarly: false },
      );
      LogRocket.error('NewQuoteProductFromFormik parsing failed, but validation succeeded?!');
    } catch (error) {
      const details =
        error instanceof Yup.ValidationError
          ? error.inner.map((inner) => `${inner.path}: ${inner.type}`).join('\n')
          : '';
      LogRocket.error(`NewQuoteProductFromFormik parsing failed. ${error}\n${details}`);
    }
    castData = {};
  }
  return NewQuoteProductFromDomainObject(castData);
};
