import { Box, Grid, Typography } from '@mui/material';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { QuotePolicy } from '../../../generated-types/QuotePolicy/QuotePolicy';

type QuoteDrawerViolationsPageProps = {
  violatedPolicies: QuotePolicy[];

  goBackToForm: () => Promise<void> | void;
  submitForm: () => Promise<void> | void;
  submitForReview: () => Promise<void> | void;
};

export const QuoteDrawerViolationsPage = ({
  violatedPolicies,
  goBackToForm,
  submitForm,
  submitForReview,
}: QuoteDrawerViolationsPageProps): JSX.Element => (
  <Box padding='5rem 3.5rem'>
    <Typography variant='h1' fontSize='2rem'>
      Issues Found
    </Typography>

    {violatedPolicies.map((policy) => (
      <Box key={policy.id} margin='2rem 0'>
        <Typography variant='h3' fontSize='1.5rem'>
          {policy.name}
        </Typography>
        <p>
          {policy.reviewerNamesDisplay()} must approve if the quote is not edited to meet these
          criteria:
        </p>
        <ul>
          {policy.thresholds.map((threshold) => (
            <li key={`${threshold.kind}|${threshold.thresholdValue}`}>{threshold.description}</li>
          ))}
        </ul>
      </Box>
    ))}

    <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
      <Grid item xs={4}>
        <ButtonPill text='Edit' variant='secondary' onClick={goBackToForm} />
      </Grid>
      <Grid item xs={4}>
        <ButtonPill text='Save Draft' variant='secondary' onClick={submitForm} />
      </Grid>
      <Grid item xs={4}>
        <ButtonPill text='Send for Review' variant='primary' onClick={submitForReview} />
      </Grid>
    </Grid>
  </Box>
);
